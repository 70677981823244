<template>
  <div class="max-w-screen-xl max-h-screen-tablet-cross-axis mx-auto py-2">
    <Header :back="false" :language="lang" 
                @langchanged="langchanged_"
                @modeChanged="modeChanged">
    </Header>
    <div class="flex flex-col justify-center text-center h-4/5 w-5/12 mx-auto tablet:mt-0 mt-40">
      <img :src="logo" alt="Logo" class="w-44 mx-auto">
      <h2 class="font-heading text-h2 text-redDamask dark:text-redDamask">{{ $t('login.thankYou') }}</h2>
      <p class="font-body text-bodyL text-sepiaBlack dark:text-halfColonialWhite mb-16">{{ $t('login.tabletSuccess') }}</p>
      <div class="mb-28 pt-10 px-20 pb-4 rounded-pin bg-gradient-to-t from-halfColonialWhite-transparent30 to-halfColonialWhite-transparent">
        <p class="font-heading text-subS text-redDamask">{{ $t('login.pin') }}</p>
        <h1 class="font-heading text-h1 text-redDamask dark:text-white">{{ pin }}</h1>
      </div> 
    </div>
    <div class=" px-4 flex tablet:pt-0 pt-60">
        <Button :text="$t('login.tabletNewRegisterBtn')" href="/tablet-register" fullWidth buttonType="secondary" />
        <Button :text="$t('login.tabletOnBoardingBtn')" :href="onboardingHref" fullWidth buttonType="primary" class="tablet:ml-72 ml-24"/>   
      </div>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Button from '@/components/Button.vue';

export default {
  name: 'TabletThankYou',
  components: {
    Header,
    Button
  },
  mounted() {
    this.$i18n.locale = this.lang;
    this.getLogo();
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_AXIOS_URL,
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      btnDisabel: false,
      pin: this.$route.query.pin,
      onboardingHref: "/tablet-onboarding?pin=" + this.$route.query.pin,
      logo: "",
    }
  },
  methods: {
    langchanged(value) {
        this.lang = value;
    },
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        this.logo = require("../assets/logo_dark.png");
      } else {
        this.logo = require("../assets/logo_light.png");
      }
    },
    modeChanged(value) {
        this.getLogoAfterChange(value);
    },
    getLogoAfterChange(value) {
      if (value == 'dark') {
        this.logo = require("../assets/logo_dark.png");
      } else {
        this.logo = require("../assets/logo_light.png");
      }
    },
  }
}
</script>

<style>

</style>